import React, { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Lang } from "../../../store";
import logo from "../../../pictures/logo.jpg";
import "./navBar.css";

export default function NavBar() {
  const navigate = useNavigate();
  const { language, setLanguage, toggled, setToggled } = Lang();
  const [burger_class, setBurgerClass] = useState("burger-bar-unclicked");
  const [menu_class, setMenuClass] = useState("menu-hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const handleToggle = () => {
    setToggled(!toggled);
    if (language === "ar") {
      setLanguage("en");
    } else if (language === "en") {
      setLanguage("ar");
    }
  };

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar-clicked");
      setMenuClass("menu-visible");
    } else {
      setBurgerClass("burger-bar-unclicked");
      setMenuClass("menu-hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <div className="navBarOuterContainer" dir="rtl">
      <div className="logo">
        <img src={logo} width={100} height={100} />
      </div>
      <div
        className="navBarInnerContainer"
        dir={`${language === "ar" ? "rtl" : "ltr"}`}
      >
        <ul className="navBar">
          <NavLink
            className={(navData) =>
              navData.isActive ? "active" : "link-inactive"
            }
            to="/"
          >
            {language == "ar" ? "الصفحة الرئيسية " : "Home"}
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "active" : "link-inactive"
            }
            to="/السيرةالذاتية"
          >
            {language === "ar" ? "السيرة الذاتية" : "About"}
          </NavLink>
          <NavLink
            className={(navData) =>
              navData.isActive ? "active" : "link-inactive"
            }
            to="/تواصل"
          >
            {language === "ar" ? "تواصل معنا" : "Contact Us"}
          </NavLink>
        </ul>
      </div>
      <div className="buttonContainer">
        <div className="arTag">En</div>
        <div className="innerContainer">
          <button
            onClick={() => {
              handleToggle();
            }}
            className={`toggle-btn${toggled ? "-toggled" : ""}`}
          >
            <div className="thumb"></div>
          </button>
          {console.log(language)}
        </div>
        <div className="engTag">Ar</div>
      </div>

      <div className="hamburgerMenu" onClick={updateMenu} dir="rtl">
        <div className={burger_class}></div>
        <div className={burger_class}></div>
        <div className={burger_class}></div>
      </div>

      <div className={menu_class}>
        <ul className="hamburgerNavBar" dir="rtl">
          {/* <li onClick={() => navigate("/")}>
            {language == "ar" ? "الصفحة الرئيسية " : "Home"}
          </li>
          <li onClick={() => navigate("/السيرةالذاتية")}>
            {" "}
            {language === "ar" ? "السيرة الذاتية" : "About"}
          </li>
          <li onClick={() => navigate("/تواصل")}>
            {" "}
            {language === "ar" ? "تواصل معنا" : "Contact Us"}
          </li> */}

          <NavLink
            className={(navData) =>
              navData.isActive ? "active" : "link-inactive"
            }
            to="/"
          >
            {language == "ar" ? "الصفحة الرئيسية " : "Home"}
          </NavLink>

          <NavLink
            className={(navData) =>
              navData.isActive ? "active" : "link-inactive"
            }
            to="/السيرةالذاتية"
          >
            {language === "ar" ? "السيرة الذاتية" : "About"}
          </NavLink>

          <NavLink
            className={(navData) =>
              navData.isActive ? "active" : "link-inactive"
            }
            to="/تواصل"
          >
            {language === "ar" ? "تواصل معنا" : "Contact Us"}
          </NavLink>
        </ul>
      </div>
    </div>
  );
}
