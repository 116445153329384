import React from "react";
import { Lang } from "../../../store";
import NavBar from "../../../global/components/navBar/navBar";
// import mainVid from "../../../pictures/mainVid.mp4";
import tryVid from "../../../pictures/backgroundVid.mp4";
import "../styling/landingPage.css";

export default function LandingPage() {
  const { language } = Lang();
  return (
    <div>
      <NavBar />
      <div className="main">
        <div className="overlay"></div>
        <video
          src={tryVid}
          autoPlay={true}
          loop={true}
          controls={false}
          playsInline
          muted
        />
        <div className="content">
          <p className={`text-${language === "ar" ? "ar" : "en"}`}>
            {language === "ar"
              ? "الرادود الحسيني الملا خضر عباس "
              : " Mulla Khader Abbas"}
          </p>
        </div>
      </div>
    </div>
  );
}
