import React, { useEffect, useState, useRef } from "react";
import { Lang } from "../../../store";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "../styling/latestVideos.css";

export default function OtherVideosPage() {
  const { language } = Lang();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="outerVideoContainer">
      <div className="title">
        {" "}
        <p>{language == "ar" ? "مزيد من الفيديوهات" : "More Videos"}</p>
      </div>
      <div className="sliderContainer">
        <Slider {...settings}>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/4OJRb_8aWkU?si=hPvlQfv3Ji3RLHby/?autoplay=1>  <img src= "http://img.youtube.com/vi/4OJRb_8aWkU/maxresdefault.jpg
    " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/rtIJEJU3nR4?si=PZGPsHhhGAas9kAV/?autoplay=1>  <img src= "http://img.youtube.com/vi/rtIJEJU3nR4/maxresdefault.jpg
    " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/32BC8Yr-5Oc?si=aXjtYNIADqkFGTSJ/?autoplay=1>  <img src= "http://img.youtube.com/vi/32BC8Yr-5Oc/maxresdefault.jpg
    " alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/qZpaFKL1dh0?si=j71d1mLpPwWd0N9O/?autoplay=1>  <img src= "http://img.youtube.com/vi/qZpaFKL1dh0/maxresdefault.jpg
            " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/Jb0VvZFWkuw?si=L3MqwU3HUEXqzLr_/?autoplay=1>  <img src= "http://img.youtube.com/vi/Jb0VvZFWkuw/maxresdefault.jpg
                " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </Slider>
      </div>
    </div>

    // </div>
  );
}
