import React from "react";
import AboutUsLandingPage from "../components/aboutUsLandingPage";

export default function AboutUsPage() {
  return (
    <div>
      <AboutUsLandingPage />
    </div>
  );
}
