import React from "react";
import ContactUsForm from "../components/contactUsForm";
import NavBar from "../../../global/components/navBar/navBar";
import Footer from "../../../global/components/footer/footer";

export default function ContactUsPage() {
  return (
    <div>
      <NavBar />
      <ContactUsForm />
      <Footer />
    </div>
  );
}
