import React, { useEffect, useState, useRef, useId, useCallback } from "react";
import { Lang } from "../../../store";
import { motion } from "framer-motion";
import "../styling/mostViewedVideos.css";
import logo from "../../../pictures/logo.jpg";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "../styling/latestVideos.css";

export default function MostViewedVideosPage() {
  // return (
  //   <div className="mostViewedContainer" dir="ltr">
  {
    /* <div className="title">
        {language == "ar" ? "الاكثر مشاهدة" : " Most Viewed Videos"}
      </div>
      <motion.div
        className="videoOuterContainer"
        whileTap={{ cursor: "grabbing" }}
        ref={carousel}
      >
        <motion.div
          className="videoContainer"
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
         dragConstraints={carousel}
        >
          {" "}
          <motion.div className="video">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/vCE-Iwh_Uac?si=MZ8sR_UOLRPPT5SF/?autoplay=1>  <img src= "http://img.youtube.com/vi/vCE-Iwh_Uac/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </motion.div>
          <motion.div className="video">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/_WfLsu4V6kU?si=HH7ozpEVtDUpqsVU/?autoplay=1>  <img src= "http://img.youtube.com/vi/_WfLsu4V6kU/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </motion.div>
          <motion.div className="video">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/cr4eBrV0YK4?si=V-zsF0ZuecAhusZW/?autoplay=1>  <img src= "http://img.youtube.com/vi/cr4eBrV0YK4/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </motion.div>
          <motion.div className="video">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/viUMyzgu5pI?si=RqpD0YnvcteUwB1t/?autoplay=1>  <img src= "http://img.youtube.com/vi/viUMyzgu5pI/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </motion.div>
          <motion.div className="video">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/cSQQiMA_p10?si=fFBa-DsnPIwft8J0/?autoplay=1>  <img src= "http://img.youtube.com/vi/cSQQiMA_p10/maxresdefault.jpg
                " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </motion.div>
        </motion.div>
      </motion.div> */
  }
  // </div>
  const { language } = Lang();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="outerVideoContainer">
      <div className="title">
        {" "}
        <p>{language == "ar" ? "الاكثر مشاهدة" : " Most Viewed Videos"}</p>
      </div>
      <div className="sliderContainer">
        <Slider {...settings}>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/vCE-Iwh_Uac?si=MZ8sR_UOLRPPT5SF/?autoplay=1>  <img src= "http://img.youtube.com/vi/vCE-Iwh_Uac/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/_WfLsu4V6kU?si=HH7ozpEVtDUpqsVU/?autoplay=1>  <img src= "http://img.youtube.com/vi/_WfLsu4V6kU/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/cr4eBrV0YK4?si=V-zsF0ZuecAhusZW/?autoplay=1>  <img src= "http://img.youtube.com/vi/cr4eBrV0YK4/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/viUMyzgu5pI?si=RqpD0YnvcteUwB1t/?autoplay=1>  <img src= "http://img.youtube.com/vi/viUMyzgu5pI/maxresdefault.jpg
              " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="videoContainer">
            <iframe
              srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/cSQQiMA_p10?si=fFBa-DsnPIwft8J0/?autoplay=1>  <img src= "http://img.youtube.com/vi/cSQQiMA_p10/maxresdefault.jpg
                " alt='Youtube Video'><span>▶</span></a>`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </Slider>
      </div>
    </div>
  );
}
