import React from "react";
import { NavLink } from "react-router-dom";
import { Lang } from "../../../store";

import logo from "../../../pictures/logo.jpg";
import "./footer.css";

export default function Footer() {
  const { language } = Lang();
  return (
    <div className="footerContainer">
      <div className="innerContainer">
        <div className="logoContainer">
          <img src={logo} alt="Logo" className="image" />
        </div>
        <div className="linksContainer">
          <div className="title">
            {" "}
            {language == "ar" ? "اقسام الموقع" : "Quick Links"}
          </div>
          <ul className="list">
            {" "}
            <NavLink
              className={(navData) =>
                navData.isActive ? "active" : "link-inactive"
              }
              to="/"
            >
              {language == "ar" ? "الصفحة الرئيسية " : "Home"}
            </NavLink>
            {/* الصفحة الرئيسية */}
            <NavLink
              className={(navData) =>
                navData.isActive ? "active" : "link-inactive"
              }
              to="/تواصل"
            >
              {language == "ar" ? "تواصل معنا" : "Contact Us"}
            </NavLink>
            <NavLink
              className={(navData) =>
                navData.isActive ? "active" : "link-inactive"
              }
              to="/السيرةالذاتية"
            >
              {language == "ar" ? "السيرة الذاتية" : "About"}
            </NavLink>
          </ul>
        </div>
        <div className="nextMoveContainer">
          Designed and developed by{" "}
          <a href="https://nextmoveweb.com" target="_blank">
            Next Move Web
          </a>{" "}
          .
        </div>

        {/* <div className="connectContainer">
          <div className="title"> تابعنا على</div>
          <div className="logo">
            <img
              src={youtubeLogo}
              alt="Youtube channel"
              className="youtubeLogo"
            />
          </div>
          <div className="logo">
            {" "}
            <img src={tiktokLogo} alt="TikTok account" className="tiktokLogo" />
          </div>

          <div className="logo">
            {" "}
            <img
              src={instagramLogo}
              alt="Instagram account"
              className="instagramLogo"
            />{" "}
          </div>
          <div className="logo">
            <img
              src={facebookLogo}
              alt="facebook account"
              className="facebookLogo"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
