import React, { useRef, useState } from "react";
import { Lang } from "../../../store";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import "../styling/contactUsForm.css";

export default function ContactUsForm() {
  const { language } = Lang();
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);

  // {
  //   language == "arr"
  //     ? "تم إرسال رسالتك بنجاح"
  //     : "Message was submitted successfully";
  // }
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_po8gevq", "template_13ck1zl", form.current, {
        publicKey: "vK7XO98BRQwAe2L4j",
      })
      .then(
        () => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "تم إرسال رسالتك بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          setSubmitted(true);
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <div className="contactUsContainer">
      <motion.div
        className="formContainer"
        initial={{ opacity: 0, y: 75 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, delay: 0.65 },
        }}
        viewport={{ once: true }}
      >
        <div className="innerContainer">
          <div className="title">
            {language == "ar" ? " تواصل معنا" : "Contact Us"}
          </div>
          <form className="form" ref={form} onSubmit={sendEmail}>
            <div className="firstNameContainer">
              <input
                type="text"
                placeholder={language == "ar" ? "الاسم" : "Name"}
                className="firstName"
                name="user_name"
                required
              />
            </div>

            <div className="phoneNumberContainer">
              <input
                type="number"
                placeholder={language == "ar" ? " رقم الهاتف" : "Phone number"}
                className="phoneNumber"
                name="user_number"
                required
              />
            </div>
            <div className="emailContainer">
              <input
                type="email"
                placeholder={
                  language == "ar"
                    ? " عنوان البريد الإلكتروني"
                    : "Email address"
                }
                className="email"
                name="user_email"
                required
              />
            </div>

            <div className="messageContainer">
              <textarea
                type="text"
                placeholder={language == "ar" ? " الرسالة" : "Message"}
                className="message"
                name="user_message"
                required
              />
            </div>
            <div className="submitButtonContainer">
              <button className="submitButton" type="submit" value="Send">
                {language == "ar" ? " أرسل" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
      {/* <div className="placeHolder"></div> */}
    </div>
  );
}
