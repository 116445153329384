import React from "react";
import NavBar from "../../../global/components/navBar/navBar";
import { Lang } from "../../../store";
import Footer from "../../../global/components/footer/footer";
import personalPic from "../../../pictures/profilePic.jpg";
import facebookLogo from "../../../pictures/facebookLogo.png";
import instagramLogo from "../../../pictures/instagramLogo.png";
import tikTokLogo from "../../../pictures/tiktokLogo.png";
import youtubeLogo from "../../../pictures/youtubeLogo.png";
import banner from "../../../pictures/banner.jpg";
import pic2 from "../../../pictures/Comp.jpg";
import "../styling/aboutUsLandingPage.css";
import SocialMedia from "../../homePage/components/socialMedia";

export default function AboutUsLandingPage() {
  const { language } = Lang();
  return (
    <div>
      <NavBar />
      <div className="bannerContainer">
        <img src={banner} alt="banner" className="banner" />
      </div>
      <div className="aboutUsContainer">
        <div className="title">
          <p dir={`${language == "ar" ? "rtl" : "ltr"}`} className="testing">
            {language == "ar"
              ? "سيرة الرادود الحسيني الملا خضر عباس"
              : "Biography of Mulla Khader Abbas"}
          </p>
        </div>

        <div className="pictureContainer">
          <img src={personalPic} className="item1" />
        </div>
      </div>
      <div
        className="textContainer"
        dir={`${language === "ar" ? "rtl" : `ltr`}`}
      >
        <p className="text">
          {" "}
          {`${
            language === "ar"
              ? `خضر عباس، رادود مُنشِد ومُلحن شيعي ، وُلِد عام ١٩٩١ في جمهورية
            العراق، تحديداً في محافظة النجف. بدأ الملا خضر عباس مسيرته الحسينية
            في عام (٢٠٠٢) حيث ارتقى المنابر الحسينية منذ الصغر، عاش و ترعرع في
            اجواء حسينية كربلائية رسخت فيه الحب و الولاء لأهل البيت. شارَك الملا
            خضر عباس في مُعظم محافظات العِراق ، بما فيها العتبات المُقدسة ،
            بالإضافة إلى مشاركته في المجالس الحُسينية خَارج العِراق. اصبح اسماً
            رائداً في مجال القصيدة الحسينية و حاز على العديد من الدروع و التكريم
            نتيجة لابداعه في إلقاء القصائد. حصلت قناته على اليوتيوب على اكثر من
            مليون مشترك كما حازت الكثير من قصائده على اهتمام الجمهور، حيث تصدرت
            بندرية "هلا بحسين الثاني" على قناة اليوتيوب، أكثر من ٩٩ مليون مشاهدة
            ، تلتها بندرية "مشاي" ٣٧ مليون مشاهدة ، وثم "هلي الغربة" ما يقارب ٣٥
            مليون مشاهدة.`
              : `Khader Abbas, a Shia reciter and composer, was born in 1991 in  Iraq, specifically in Najaf. Mullah Khader Abbas began his Hussaini career in 2002, ascending the Hussaini pulpits from a young age. He lived and grew up in the Hussaini Karbala atmosphere, which instilled in him love and loyalty to Ahl al-Bayt. Mullah Khader Abbas has participated in most Iraqi provinces, including the holy shrines, as well as in Hussaini gatherings outside Iraq.

He has become a leading name in the field of Hussaini poetry and has received numerous shields and awards for his creativity in reciting poems. His YouTube channel has garnered over a million subscribers, and many of his poems have gained significant attention from the audience. His poem "Hala B Hussein Al Thani" has achieved over 99 million views on YouTube, followed by "Mashai" with 37 million views, and then "Hali Al Ghurba" with approximately 35 million views.`
          }`}{" "}
        </p>
      </div>

      <div className="pictureContainer2">
        <img src={pic2} className="item2" />
      </div>

      <div className="textContainer">
        <p className="text" dir={`${language === "ar" ? "rtl" : `ltr`}`}>
          {/* {`${language === "ar" ? "  تم إختيار أفضل الرواديد من حيث الإنتاج والتلحين والوصول لمحرم الحرام لعام 1446 هجري:" : " "}`}</p> */}
          {`${
            language === "ar"
              ? `تم إختيار أفضل الرواديد من حيث الإنتاج والتلحين والوصول لمحرم الحرام لعام 1446 هجري ، بحسب تصنيف برنامج تحليل الأداء :

 


#سلسلة_الإحصاء_والأداء.`
              : `The best reciters have been selected in terms of production, composition, and accessibility for Muharram 1446 AH, 
                according to the Performance Analysis Program classification: 
              `
          }`}{" "}
        </p>
      </div>
      <div className="text2" dir={`${language === "ar" ? "rtl" : `ltr`}`}>
        <p>
          {" "}
          {`${
            language === "ar"
              ? `1. قصائد الاستوديو - الرادود الحسيني (باسم الكربلائي).`
              : ""
          }`}
        </p>
        <p>
          {`${
            language === "ar"
              ? `  ٢. قصائد المجالس للعشرة الأولى من محرم - الرادود الحسيني (خضر عباس).`
              : ""
          }`}
        </p>
      </div>
      <div className="text2" dir={`${language === "ar" ? "rtl" : `ltr`}`}>
        <p>
          {" "}
          {`${
            language === "en"
              ? `1. Studio Poems - Hussaini Reciter (Basim Al-Karbalaei).`
              : ""
          }`}
        </p>
        <p>
          {`${
            language === "en"
              ? `2. Majalis Poems for the First Ten Days of Muharram - Hussaini Recit.`
              : ""
          }`}
        </p>
      </div>

      <SocialMedia />

      <Footer />
      {/* /*   <div className="container">
        <div className="title">
          <p dir={`${language == "ar" ? "rtl" : "ltr"}`} className="testing">
            {language == "ar"
              ? "سيرة الرادود الحسيني الملا خضر عباس"
              : "Biography of Mulla Khudair Abbas"}
          </p>
        </div>

        <div className="pictureContainer">
          <img src={personalPic} className="profilePic" height={400} />
          <div className="socialContainer">
            <p className="socialText">
              {" "}
              {language == "ar"
                ? "تابعنا على وسائل التواصل الاجتماعي"
                : "Follow me on social Media"}
            </p>
            <ul className="list">
              <li>
                <a
                  href="https://www.facebook.com/KhaderKhaderAbbas?mibextid=LQQJ4d"
                  target="_blank"
                >
                  <img src={facebookLogo} height={50} className="images" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/khader.abbas/?igsh=MXVzMHY4cXZjOHIwMw%3D%3D"
                  target="_blank"
                >
                  <img src={instagramLogo} height={50} className="images" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@khaderabba?_t=8o84L1UlA5v&_r=1"
                  target="_blank"
                >
                  <img src={tikTokLogo} height={50} className="images" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@khader_abbas" target="_blank">
                  <img src={youtubeLogo} height={50} className="images" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="textContainer">
          <p className="text">
            خضر عباس، رادود مُنشِد ومُلحن شيعي ، وُلِد عام ١٩٩١ في جمهورية
            العراق، تحديداً في محافظة النجف. بدأ الملا خضر عباس مسيرته الحسينية
            في عام (٢٠٠٢) حيث ارتقى المنابر الحسينية منذ الصغر، عاش و ترعرع في
            اجواء حسينية كربلائية رسخت فيه الحب و الولاء لأهل البيت. شارَك الملا
            خضر عباس في مُعظم محافظات العِراق ، بما فيها العتبات المُقدسة ،
            بالإضافة إلى مشاركته في المجالس الحُسينية خَارج العِراق. اصبح اسماً
            رائداً في مجال القصيدة الحسينية و حاز على العديد من الدروع و التكريم
            نتيجة لابداعه في إلقاء القصائد. حصلت قناته على اليوتيوب على اكثر من
            مليون مشترك كما حازت الكثير من قصائده على اهتمام الجمهور، حيث تصدرت
            بندرية "هلا بحسين الثاني" على قناة اليوتيوب، أكثر من ٩٩ مليون مشاهدة
            ، تلتها بندرية "مشاي" ٣٧ مليون مشاهدة ، وثم "هلي الغربة" ما يقارب ٣٥
            مليون مشاهدة.
          </p>
        </div>

     
          
          
       
        </div>
      </div>  */}

      {/* <Footer /> */}
    </div>
  );
}
