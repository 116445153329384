import React, { useContext, useState, createContext } from "react";

export const Context = createContext({
  language: null,
  setLanguage: () => Promise,
  toggled: null,
  setToggled: () => Promise,
});

export const Lang = () => useContext(Context);

// const Store = ({ children }) => {
//   const [language, setLanguage] = useState("");

//   return (
//     <Context.Provider value={[language, setLanguage]}>
//       {" "}
//       {children}
//     </Context.Provider>
//   );
// };

export default function Store({ children }) {
  const [language, setLanguage] = useState("ar");
  const [toggled, setToggled] = useState(false);
  const value = {
    language,
    setLanguage,
    toggled,
    setToggled,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}
