import React from "react";
import { Lang } from "../../../store";
import facebookLogo from "../../../pictures/facebookLogo.png";
import instagramLogo from "../../../pictures/instagramLogo.png";
import tikTokLogo from "../../../pictures/tiktokLogo.png";
import youtubeLogo from "../../../pictures/youtubeLogo.png";
import snapChatLogo from "../../../pictures/snapChatLogo.png";
import telegramLogo from "../../../pictures/telegram.png";
import xLogo from "../../../pictures/xLogo.png";
import whatsAppLogo from "../../../pictures/whatsapp.png";

import "../styling/socialMedia.css";
export default function SocialMedia() {
  const { language } = Lang();
  return (
    <div className="socialMediaContainer">
      {/* <div className={`title-${language == "ar" ? "ar" : "en"}`}> */}
      <div className="title">
        {language == "ar"
          ? "تابعنا على وسائل التواصل الاجتماعي"
          : " Follow Us on Social Media"}
      </div>
      <div className="outerContainerTest">
        <div className="Test">
          <a
            href="https://www.facebook.com/KhaderKhaderAbbas?mibextid=LQQJ4d"
            target="_blank"
          >
            <img src={facebookLogo} height={50} className="pic-fb" />
          </a>
        </div>
        <div className="Test">
          <a
            href="https://www.instagram.com/khader.abbas/?igsh=MXVzMHY4cXZjOHIwMw%3D%3D"
            target="_blank"
          >
            <img src={instagramLogo} height={50} className="pic-ig" />
          </a>
        </div>
        <div className="Test">
          <a
            href="https://www.tiktok.com/@khaderabba?_t=8o84L1UlA5v&_r=1"
            target="_blank"
          >
            <img src={tikTokLogo} height={50} className="pic" />
          </a>
        </div>
        <div className="Test">
          <a href="https://www.youtube.com/@khader_abbas" target="_blank">
            <img src={youtubeLogo} height={50} className="pic" />
          </a>
        </div>
      </div>
      <div className="outerContainerTest2">
        <div className="Test">
          <a
            href="https://whatsapp.com/channel/0029VaEq7Qb65yD7ah2pme1x"
            target="_blank"
          >
            <img src={whatsAppLogo} height={50} className="pic-fb" />
          </a>
        </div>
        <div className="Test">
          <a href="https://twitter.com/MolakhaderAbbas" target="_blank">
            <img src={xLogo} height={50} className="pic-ig" />
          </a>
        </div>
        <div className="Test">
          <a href="https://telegram.me/Khader_Abbas" target="_blank">
            <img src={telegramLogo} height={50} className="pic" />
          </a>
        </div>
        <div className="Test">
          <a href="https://t.snapchat.com/GIafBtY0" target="_blank">
            <img src={snapChatLogo} height={50} className="pic" />
          </a>
        </div>
      </div>
    </div>
  );
}
