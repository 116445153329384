import React, { useEffect, useState } from "react";
import { Lang } from "../../../store";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "../styling/latestVideos.css";

export default function LatestVideosPage() {
  const { language } = Lang();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const channelId = "UCWUPGzXhoHtXWgycAZ6AuAQ";

  var fetchUrl = `https://www.googleapis.com/youtube/v3/search?key=${process.env.REACT_APP_YOUTUBE_API_KEY}&channelId=${channelId}&part=snippet,id&order=date&maxResults=6&type=video&videoEmbeddable=true`;
  const [allvideos, setAllVideos] = useState([]);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const Fetch = () => {
      fetch(fetchUrl).then((response) => {
        if (response.ok) {
          response.json().then((resJson) => {
            const result = resJson.items.map((doc) => ({
              ...doc,
              Videolink: "https://www.youtube.com/embed/" + doc.id.videoId,
            }));
            setAllVideos(result);
            setShowVideo(true);
          });
        } else {
          setShowVideo(false);
        }
      });
    };

    Fetch();
  }, []);

  console.log(allvideos);
  const SetVideos = () => {
    return (
      <div className="outerVideoContainer">
        {console.log(allvideos.size)}
        <div className="title">
          {" "}
          <p>{language === "ar" ? "أحدث الفيديوهات" : "Latest Videos"}</p>
        </div>
        <div className="sliderContainer2">
          <Slider {...settings}>
            {!showVideo ? (
              <div className="videoContainer">
                <iframe
                  srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/xFle5QO0z5g/?autoplay=1>  <img src= "http://img.youtube.com/vi/xFle5QO0z5g/maxresdefault.jpg
 " alt='Youtube Video'><span>▶</span></a>`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            ) : (
              ""
            )}
            {allvideos.map((item) => {
              return (
                <div className="videoContainer">
                  <iframe
                    srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=${item.Videolink}/?autoplay=1 > <img src= "http://img.youtube.com/vi/${item.id.videoId}/maxresdefault.jpg
    " alt='Youtube Video'><span>▶</span></a>`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              );
            })}
          </Slider>
          {!showVideo ? (
            <div className="videoContainer">
              <iframe
                srcDoc={` <style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style> <a href=https://www.youtube.com/embed/xFle5QO0z5g/?autoplay=1>  <img src= "http://img.youtube.com/vi/xFle5QO0z5g/maxresdefault.jpg
 " alt='Youtube Video'><span>▶</span></a>`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    SetVideos();
    console.log("The value of the showVideo is : " + showVideo);
  }, [showVideo]);

  console.log(allvideos);
  return SetVideos();
}
