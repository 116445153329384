import React from "react";

import LandingPage from "../components/landingPage";
import MostViewedVideosPage from "../components/mostViewedVideos";
import LatestVideosPage from "../components/lastestVideos";
import SocialMedia from "../components/socialMedia";
import Footer from "../../../global/components/footer/footer";
import OtherVideosPage from "../components/otherVideos";

export default function HomePage() {
  return (
    <div>
      <LandingPage />
      <MostViewedVideosPage />
      <LatestVideosPage />
      <OtherVideosPage />
      <SocialMedia />
      <Footer />
    </div>
  );
}
