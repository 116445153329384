import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from "./store";
import HomePage from "./pages/homePage/src/homePage";
import ContactUsPage from "./pages/contactUs/src/contactUsPage";
import AboutUsPage from "./pages/aboutUsPage/src/aboutUsPage";

import "./App.css";
import { Analytics } from "@vercel/analytics/react";
function App() {
  return (
    <Store>
      <Analytics />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/تواصل" element={<ContactUsPage />} />
          <Route path="/السيرةالذاتية" element={<AboutUsPage />} />
        </Routes>
      </BrowserRouter>
    </Store>
  );
}

export default App;
